import bus from 'vue3-eventbus'
import { PublicStore } from "@/stores/public";
import { TradeStore } from '@/stores/trade';
import { HomeStore } from '@/stores/home';
export default class Socket {
    socket = null
    maxReload = 10
    publicStore = null
    tradeStore = null
    homeStore = null
    timer = null
    heartbeatTime = 10
    ws_state = false
    // $vm = null
    constructor(wsurl) {
        this.wsurl = wsurl
        this.publicStore = PublicStore();
        this.tradeStore = TradeStore();
        this.homeStore = HomeStore();
        this.createSocket()
    }
    createSocket() {
        this.socket = new WebSocket(this.wsurl)
        this.socket.onopen = this._onopen.bind(this)
        this.socket.onclose = this._onclose.bind(this)
        this.socket.onerror = this._onerror.bind(this)
        this.socket.onmessage = this._onmessage.bind(this)
    }
    _onopen() {
        this.ws_state = true;
        this.publicStore.setSocket(this)
        bus.emit('ws-connection')
        this.heartbeat()
    }
    _onclose() {
        // 关闭连接
        clearInterval(this.timer)
        this.ws_state = false
        this.publicStore.setSocket(null)
        bus.emit('ws-disconnect')
        //断线重连
        if (this.maxReload > 0) {
            setTimeout(() => {
                this.createSocket()
                this.maxReload -= 1
            }, 1000)
        }
    }
    _onerror() {
        //错误，结束
        clearInterval(this.timer)
        this.ws_state = false
        this.socket.close()
    }
    _onmessage(msg) {
        let data = JSON.parse(msg.data)
        bus.emit('ws-message', data)
        if(data && data.message && data.message.info ) {
            data =  data.message.info;
            if(data.cmd_id == 22999) {
                bus.emit('ws-depth', data.data)
                let tmp = JSON.parse(JSON.stringify(data.data))
                tmp.asks = tmp.asks.map(item => {
                    return [item.price,item.volume]
                })
                tmp.bids = tmp.bids.map(item => {
                    return [item.price,item.volume]
                })
                if(tmp.code == this.homeStore.currentSymbol.replace('/','')) {
                    this.tradeStore.setOrderbookData(tmp)
                }
                
            }
            if(data.cmd_id == 22998) {
                bus.emit('ws-quote', data.data)
                let tmp = JSON.parse(JSON.stringify(this.homeStore.pairList));
                
                if(tmp.length == 0) return
                tmp = tmp.map(item => {
                    let rangeType = item.rangeType || 'up';
                    if(data && data.data && data.data.code == item.info.code) {
                        let lastClose = item.close;
                        if(lastClose > data.data.price) {
                            rangeType = 'down'
                        }else {
                            rangeType = 'up'
                        }
                        return {
                            ...item,
                            close:data.data.price,
                            rangeType,
                            info:{
                                ...item.info,
                                price:data.data.price,
                                turnover:data.data.turnover,
                                volume:data.data.volume
                            }
                        }
                    }
                    return {
                        ...item,
                        rangeType
                    }
                })
                if(data.data.code == 'BTCUSDT') {
                    let b = tmp.filter(item => {
                        return item.symbol == 'BTC/USDT'
                    })
                }
                this.homeStore.setPairList(tmp)
            }
        }
        
    }
    send(data) {
        //发送订阅 do
        if(this.ws_state) {
            data = JSON.stringify(data)
            this.socket.send(data)
            return this
        }
    }
    close() {
        this.socket.close()
    }
    heartbeat() {
        let params = {
            method:"HB",
        }
        this.send(params)
        this.timer = setInterval(() => {
            this.send(params)
        },this.heartbeatTime * 1000)
    }
}