<template>
  <div class="navigation-bar">
    <div
      class="item"
      :class="tabbarIndex == 0 && 'active'"
      @click="jumpPage('/home', 0)"
    >
      <v-icon> mdi-sun-compass </v-icon>
      <div>{{ $t("mobile.text1") }}</div>
    </div>
    <div
      class="item"
      :class="tabbarIndex == 1 && 'active'"
      @click="launchClick"
    >
      <div class="bar">
        <v-icon> mdi-cog-transfer </v-icon>
        <div>{{ $t("mobile.text5") }}</div>
      </div>
    </div>
    <div
      class="item"
      :class="tabbarIndex == 2 && 'active'"
      @click="jumpPage('/portfolio', 2)"
    >
      <v-icon> mdi-wallet </v-icon>
      <div>{{ $t("mobile.text6") }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { PublicStore } from "@/stores/public";
const publicStore = PublicStore();
const route = useRoute();
const tabbarIndex = ref(0);
function jumpPage(url, tab) {
  router.push(url);
  tabbarIndex.value = tab;
}
function launchClick() {
  if (route.path != "/m/home") {
    router.push("/m/home");
    setTimeout(() => {
      publicStore.setCreateBot(true);
    }, 1500);
  } else {
    publicStore.setCreateBot(true);
  }
}
</script>

<style lang="scss" scoped>
.navigation-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 10;
  //   background-color: var(--v-primary-base);
  background-color: #17181b;
  .item {
    flex: 1;
    text-align: center;
    position: relative;
    &.active {
      i,
      div {
        color: #2eaeffba;
      }
    }
    i {
      font-size: 30px;
    }
    div {
      font-size: 12px;
    }
    .bar {
      position: absolute;
      top: -16px;
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #252525;
      padding-top: 10px;
      //   box-shadow: 0 0px 0 8px var(--v-primary-base);
      box-shadow: 0 0px 0 8px #17181b;
      &:active {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.4), 0 0 0 rgba(42, 42, 42, 0.9),
          inset -7px -7px 12px rgba(17, 17, 17, 0.9),
          inset 7px 7px 12px rgba(0, 0, 0, 0.4);
      }
      i {
        margin-bottom: 5px;
      }
    }
  }
}
</style>