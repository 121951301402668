<template>
  <v-dialog v-model="dialog" persistent max-width="460">
    <v-card>
      <div
        class="pa-4 pt-3 pb-3 card-head text-uppercase font-weight-black font-primary-14"
      >
        {{ title }}
      </div>
      <v-divider></v-divider>

      <div class="pa-4">
        <p class="font-secondary-14 mb-4">{{ text }}</p>
      </div>
      <v-divider></v-divider>
      <div class="foot d-flex justify-end align-center pa-4 pt-2 pb-2">
        <v-btn
          @click="close"
          variant="outlined"
          color="hsla(0,0%,100%,.12)"
          class="rounded-pill pl-3 pr-3 black--text"
          :loading="cancelLoading"
        >
          <span class="font-primary-12">{{cancelText}}</span>
        </v-btn>
        <v-btn
          @click="confirm"
          v-if="confirmText"
          :loading="loading"
          color="#fff"
          class="rounded-pill pl-3 pr-3 ml-4"
        >
          <span class="font-primary-12 black--text">{{ confirmText }}</span>
        </v-btn>
      </div>
      <!-- <template v-slot:actions>
        <v-spacer></v-spacer>
        
      </template> -->
    </v-card>
  </v-dialog>
</template>
  
<script setup>
import { defineProps, defineEmits, computed } from "vue";
const emits = defineEmits(["close","confirm"]);
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  confirmText: {
    type: String,
    default: "Confirm",
  },
  cancelText:{
    type: String,
    default: "Cancel",
  },
  loading:{
    type: Boolean,
    default: false,
  },
  cancelLoading:{
    type: Boolean,
    default: false,
  }
});
const dialog = computed(() => {
  return props.isShow;
});
function confirm() {
  emits("confirm");
}
function close() {
  emits("close", false);
}
</script>
  
  <style lang="scss" scoped>
.bot-type-item {
  border: 1px solid #323232;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: #272727;
  }
}
</style>