export const checkTokenExpired = (token) =>{
    try {
        let strings = token.split("."); //截取token，获取载体
        const decodedPayload = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
        const currentTime = Date.now() / 1000;
        return decodedPayload.exp < currentTime;
    } catch (error) {
        return true
    }
}
