<template>
  <div class="header-component">
    <div class="pc-header">
      <div class="pc-content d-flex justify-end align-center pl-4 pr-4">
        <a
          target="_black"
          class="font-primary-14 link"
          href="https://www.algo724.com/blog-pages/blog"
          >BLOG</a
        >
        <a
          target="_black"
          class="font-primary-14 link ml-8 mr-6"
          href="https://t.me/algo724"
          >TELEGRAM</a
        >
        <template v-if="isLogin">
          
          <v-menu offset-y location="bottom" transition="scale-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                height="34"
                variant="outlined"
                color="hsla(0,0%,100%,.12)"
                text
                prepend-icon="mdi-account"
                class="rounded-pill ml-4"
                v-bind="props"
              >
                <template v-slot:prepend>
                  <v-avatar size="32" width="30" height="30">
                    <v-icon color="#fff">mdi-account</v-icon>
                  </v-avatar>
                </template>
                <p class="mb-0 pr-4 btn-label text-lowercase">
                  {{ userInfo.userName }}
                  <!-- {{ userInfo.email.length > 15 ? userInfo.email.slice(0,4) + '...' + userInfo.email.slice(-6) : userInfo.email}} -->
                </p>
              </v-btn>
            </template>

            <v-card class="menu-content-box" color="#121212" width="360">
              <div class="content-box pt-5 pb-5">
                <div class="d-flex justify-center">
                  <div class="avatar-box">
                    {{
                      userInfo &&
                      userInfo.userName &&
                      userInfo.userName.slice(0, 1).toUpperCase()
                    }}
                  </div>
                  
                </div>
                <p class="text-center font-weight-bold mt-2">{{ userInfo.userName }}</p>
                <div class="pa-4 pt-2 pb-2 mt-4">
                  <div class="code-box">
                    <div class=" pa-3">
                      <div class="">
                        <p class="font-primary-16 mb-1">Email</p>
                        <p class="font-secondary-12 mb-0">
                          <!-- {{ registerLink }} -->
                          {{ userInfo.email }}
                        </p>
                      </div>
                      <!-- <v-btn
                        variant="text"
                        size="small"
                        :data-clipboard-text="registerLink"
                        class="copyBtn"
                        >Copy</v-btn
                      > -->
                    </div>
                  </div>
                </div>
                <!-- <v-divider class="mt-4 mb-4"></v-divider> -->
                <!-- <div class="row-menu d-flex justify-space-between align-center">
                  <div class="d-flex align-center">
                    <img
                      src="../../../public/images/sidebar/sync.png"
                      alt=""
                      width="22"
                    />
                    <p class="menu-text ml-3 mb-0">Demo account</p>
                  </div>
                  <v-switch
                    class="ma-0"
                    inset
                    color="info"
                    hide-details
                    @click.stop
                    v-model="_data.isDemo"
                    @change="changeDemoType"
                  ></v-switch>
                </div> -->
                <v-divider class="mt-4 mb-4"></v-divider>
                <div
                  class="row-menu d-flex justify-space-between align-center"
                  v-for="item in headMenu"
                  :key="item.name"
                  @click="toPageClick(item.url)"
                >
                  <div class="d-flex align-center">
                    <img :src="item.icon" width="25" alt="" />
                    <span class="menu-text ml-3">{{ item.title }}</span>
                  </div>
                </div>
                <v-divider class="mt-4 mb-4"></v-divider>
                <div
                  class="row-menu d-flex justify-space-between align-center"
                  @click="logoutClick"
                >
                  <div class="d-flex align-center">
                    <img
                      src="../../../public/images/sidebar/logout.png"
                      width="23"
                      style="margin-left: 2px;"
                      alt=""
                    />
                    <span class="menu-text ml-3">Logout</span>
                  </div>
                </div>
              </div>
            </v-card>
          </v-menu>
          <v-btn
            class="start-new-btn rounded-pill ml-4"
            color="hsla(0,0%,100%,.12)"
            text
            variant="outlined"
            height="34"
            @click="createBotClick"
          >
            <span style="color: #fff" class="d-flex align-center">
              <v-icon small class="mr-1"> mdi-robot </v-icon>
              <p class="mb-0 btn-label">{{ $t("trade.createBot.text2") }}</p>
            </span>
          </v-btn>
          <v-btn class="rounded-pill create-webhook ml-4" height="34" variant="outlined" @click="$router.push('/webhook')">+ Create Webhook</v-btn>
          <v-btn class="text-none" stacked @click="sidebarInfoClick">
            <v-badge color="error" dot v-if="publicStore.haveNewNews2">
              <!-- <v-icon>mdi-bell-outline</v-icon> -->
              <img src="/images/setting/notice.png" width="30" alt="">
            </v-badge>
            <!-- <v-icon v-else>mdi-bell-outline</v-icon> -->
            <img v-else src="/images/setting/notice.png" width="30" alt="">
          </v-btn>
        </template>
      </div>
    </div>
    <div
      class="mobile-header d-flex justify-space-between align-center pl-4 pr-4"
    >
      <template v-if="isLogin">
        <v-icon @click="sidebarClick">mdi-menu</v-icon>
        <v-badge color="error" dot v-if="publicStore.haveNewNews2" @click="sidebarInfoClick">
          <img src="/images/setting/notice.png" width="30" alt="">
        </v-badge>
        <img v-else @click="sidebarInfoClick" src="/images/setting/notice.png" width="30" alt="">
      </template>
    </div>
    <GlobalDialog
      :is-show="_data.showDialog"
      :title="_data.tipTitle"
      :text="_data.tipText"
      :confirmText="_data.confirmText"
      @confirm="logOutConfirm"
      @close="_data.showDialog = false"
    />
  </div>
</template>

<script setup>
import GlobalDialog from "@/components/GlobalDialog.vue";
import ClipboardJS from "clipboard";
import {
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  defineEmits
} from "vue";
import { PublicStore } from "@/stores/public";
const publicStore = PublicStore();
import { useRouter } from "vue-router";
import { delCookie } from "@/utils/cookie.js";
const emits = defineEmits(["sidebar:update"]);
const router = useRouter();
const { proxy } = getCurrentInstance();
// const haveNewNews = computed(() => {
//   return publicStore.haveNewNews2
// })
const headMenu = [
  {
    title: "Account",
    name: "Account",
    icon: require("../../../public/images/sidebar/account.png"),
    url: "/account",
  },
  {
    title: "Setting",
    name: "Setting",
    icon: require("../../../public/images/sidebar/setting.png"),
    url: "/setting",
  },
];
onMounted(() => {
  var clipboard = new ClipboardJS(".copyBtn");
  clipboard.on("success", (e) => {
    proxy.$message.success("Copy success!");
    e.clearSelection();
  });
  clipboard.on("error", () => {
    proxy.$message.error("Copy faild! ");
  });
});
const _data = reactive({
  isDemo: false,
  showDialog: false,
  tipTitle: "",
  tipText: "",
  confirmText: "Confirm",
  showInfoBar:false
});
const registerLink = computed(() => {
  return (
    window.location.origin +
    "/register?referral_code=" +
    publicStore.referral_code
  );
});
const isLogin = computed(() => {
  return publicStore.isLogin;
});
const userInfo = computed(() => {
  return publicStore.userInfo;
});
function createBotClick() {
  if (location.pathname != "/home") {
    router.push("/home");
    setTimeout(() => {
      publicStore.setCreateBot(true);
    }, 2000);
  } else {
    publicStore.setCreateBot(true);
  }
}
function changeDemoType() {
  publicStore.setIsDemo(_data.isDemo);
}
function toPageClick(url) {
  router.push(url);
}
function logoutClick() {
  _data.showDialog = true;
  _data.tipTitle = "Logout";
  _data.tipText = "Are you sure you want to logout?";
}
function logOutConfirm() {
  publicStore.setIsLogin(false);
  publicStore.setReferralCode("");
  delCookie("access_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  _data.showDialog = false;
  router.push("/login");
}
function sidebarClick() {
  if (!isLogin.value) {
    return;
  }
  emits("sidebar:update");
}
function sidebarInfoClick() {
  publicStore.setHaveNewNews2(false)
  emits("sidebarinfo:update");
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar {
  left: 0 !important;
  width: 100% !important;
}
.create-webhook {
  color:#3FA2F6;
  font-size: 12px;
}
.avatar-box {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #555;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  font-weight: 600;
}
.row-menu {
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  &:hover {
    //   background: #0b23497a;
    background: #4949492e;
  }
}
.code-box {
  border-radius: 8px;
  border: 1px solid #222;
  background-color: #171717;
  .code-box-left {
    max-width: 70%;
  }
}
.header-component {
  height: 100%;
  width: 100%;
  background: #121212;
  border-bottom: 1px solid #ffffff21;
  .link {
    text-decoration: none;
    font-weight: 300;
    &:hover {
      color: #48ac75;
    }
  }
  .btn-label {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
  }
  .start-new-btn {
    background-image: linear-gradient(to right, #54ad80, #26a757);
    border: 1px solid #3fdfe7;
    box-shadow: 0 0 10px 1px #3fdfe7;
    padding: 5px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
      background-image: linear-gradient(to right, #4fc489, #4ac679);
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    &:hover::before {
      -webkit-animation: sh02 0.5s 0s linear;
      -moz-animation: sh02 0.5s 0s linear;
      animation: sh02 0.5s 0s linear;
    }
    &::before {
      content: "";
      display: block;
      width: 0px;
      height: 86%;
      position: absolute;
      top: 7%;
      left: 0%;
      opacity: 0;
      background: #fff;
      box-shadow: 0 0 50px 30px #fff;
      -webkit-transform: skewX(-20deg);
      -moz-transform: skewX(-20deg);
      -ms-transform: skewX(-20deg);
      -o-transform: skewX(-20deg);
      transform: skewX(-20deg);

      //   -webkit-animation: sh02 0.5s 0s linear;
      //   -moz-animation: sh02 0.5s 0s linear;
      //   animation: sh02 0.5s 0s linear;
    }
    @keyframes sh02 {
      from {
        opacity: 0;
        left: 0%;
      }

      50% {
        opacity: 1;
      }

      to {
        opacity: 0;
        left: 100%;
      }
    }
  }
}
.pc-header,
.mobile-header,
.pc-content {
  height: 100%;
}
@media (max-width: 768px) {
  .pc-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
}
@media (min-width: 768px) {
  .pc-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }
}
</style>