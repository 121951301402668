import { createPinia, defineStore } from 'pinia'
const pinia = createPinia()
import { version } from "@/config/index"
import service from '@/servers/fetch'
// import { PublicStore } from "@/stores/public";
export const TradeStore = defineStore('Trade', {
    state: () => {
        return {
            tradeHistory: [],
            orderbookData: {},
            openOrders: [],
            historyOrders: [],
            symbolInfos:{},
            allKlines:{}
        }
    },
    actions: {
        setAllKlines(v) {
            this.allKlines = v
        },
        setHistoryOrders(v) {
            this.historyOrders = v
        },
        setOpenOrders(v) {
            this.openOrders = v
        },
        setTradeHistory(v) {
            this.tradeHistory = v
        },
        setOrderbookData(v) {
            this.orderbookData = JSON.parse(JSON.stringify(v))
        },
        async getTradeHistory(data) {
            let response = await service({
                url: `${version}/getTrade`,
                method: 'get',
                params: data
            })
            this.setTradeHistory(response.data)
            return response;
        },
        async getOrderBook(data) {
            let response = await service({
                url: `${version}/getOrderBook`,
                method: 'get',
                params: data
            })
            this.setOrderbookData(response.data[data.symbol])
            return response
        },
        async orderCreateOrder(data) {
            let response = await service({
                url: `${version}/createOrder`,
                method: 'post',
                data
            })
            return response
        },
        async getOpenOrder(data) {
            let response = await service({
                url: `${version}/getOpenOrder`,
                method: 'get',
                params: data
            })
            this.openOrders = response.data;
            return response
        },
        async getOrderHistory(data) {
            let response = await service({
                url: `${version}/getOrder`,
                method: 'get',
                params: data,
                headers:{
                    'Content-Type': 'application/json'
                }
            })
            return response
        },
        async getOhlcv(data) {
            let response = await service({
                url: `${version}/getOhlcv`,
                method: 'post',
                data,
                headers:{
                    'Content-Type': 'application/json'
                }
            })
            return response
        }
    },

})

export default pinia;