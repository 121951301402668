import binance from "../../public/images/exchanges/binance.png"
import binance_futures from "../../public/images/exchanges/binance_futures.png"
import bitfinex from "../../public/images/exchanges/bitfinex.png"
import okx from "../../public/images/exchanges/okx.png"
import wintermute from "../../public/images/exchanges/wintermute.png"
import b2c2 from "../../public/images/exchanges/b2c2.png"
import hyperex from "../../public/images/exchanges/hyperex.png"
const icons = {
    binance,
    binance_futures,
    bitfinex,
    okx,
    wintermute,
    b2c2,
    hyperex
}
import { HomeStore } from "@/stores/home";

function getDecimal(value) {
    let val = Math.abs(value)
    if (val == 0) {
        return 0
    } else if (val >= 1) {
        return 2;
    } else if (val < 1 && val >= 0.01) {
        return 4;
    } else if (val < 0.01 && val >= 0.001) {
        return 5;
    } else if (val < 0.001 && val >= 0.00001) {
        return 6;
    } else {
        return 8;
    }
}
function decimalFixed(val, decimal = null) {
    let _decimal = decimal ? decimal : getDecimal(val)
    let b = Math.pow(10, _decimal);
    let num = (parseInt(val * b) / b).toFixed(_decimal);
    return num
}
function toFixed(val, decimal = null) {
    if (isNaN(val)) {
        return '--'
    }
    let _decimal = decimal ? decimal : getDecimal(val)
    return Number(val).toFixed(_decimal)
}

function getExchangeIcon(exchange_id, exchange_type = "") {
    if (!exchange_type) {
        const homeStore = HomeStore();
        if (homeStore.formatExchangeList.length == 0) {
            return
        }
        let data = homeStore.formatExchangeList.filter(item => {
            return item.id == exchange_id
        })
        if (data.length == 0) {
            return
        }
        return icons[data[0].exchangeCode]
    }
    return icons && icons[exchange_type];
}
function numConvert(num, decimal = null) {
    if (num == '--' || num === 0 || isNaN(num) || num == Infinity || num == 'undefined') {
        return 0
    }
    let multiple, unit;
    if (num >= 1000000000000000) {
        multiple = 10000000000000
        unit = 'Q'
    } else if (num >= 1000000000000) {
        multiple = 10000000000
        unit = 'T'
    } else if (num >= 1000000000) {
        multiple = 10000000
        unit = 'B'
    } else if (num >= 1000000) {
        multiple = 10000
        unit = 'M'
    } else {
        multiple = 10
        unit = 'K'
    }
    if (num >= 1000) {
        let strNum = String(num / multiple / 100),
            sp = strNum.split('.');
        if (sp[1]) {
            return sp[0] + '.' + sp[1].slice(0, 2) + unit
        }
        return sp + unit
    }
    if (decimal) {
        return this.toFixed(num, decimal)
    }
    return num
}

export { getDecimal, decimalFixed, toFixed, getExchangeIcon, numConvert }