import { createPinia, defineStore } from 'pinia'
const pinia = createPinia()
import { version } from "@/config/index"
import service from '@/servers/fetch'


export const PublicStore = defineStore('Public', {
    state: () => {
        return {
            isLogin: false,
            need2fa: false,
            referral_code: "",
            userInfo: {},
            isDemo: false,
            createBot: false,
            showCreatePanel: {
                botType: 0,
                show: false,
                mobileShow: false,
                bot_params:{},
                strategy:{
                    up:1.1,
                    down:0.9
                }
            },
            clientWidth: "",
            registration: null,
            haveNewNews:false,
            haveNewNews2:false,
            socket:null
        }
    },
    actions: {
        setHaveNewNews(v) {
            this.haveNewNews = v
        },
        setHaveNewNews2(v) {
            this.haveNewNews2 = v
        },
        setNeed2Fa(v) {
            this.need2fa = v
        },
        setClientWidth(v) {
            this.clientWidth = v
        },
        setIsLogin(v) {
            this.isLogin = v
        },
        setIsDemo(v) {
            this.isDemo = v
        },
        setReferralCode(v) {
            this.referral_code = v
        },
        setUserInfo(v) {
            this.userInfo = v
        },
        setCreateBot(v) {
            this.createBot = v
        },
        setShowCreatePanel(v) {
            this.showCreatePanel = v
        },
        setRegistration(v) {
            this.registration = v
        },
        setSocket(v) {
            this.socket = v
        },
        loginFetch: async (data) => {
            return service({
                url: `${version}/login`,
                method: 'post',
                data,
            })
        },
        async getUserInfoFuc() {
            let res = await service({
                url: `${version}/getUser`,
                method: 'get',
            })
            if (res) {
                this.setUserInfo(res.data)
                this.setIsLogin(true)
            }
            return res
        },
        async getReferralCode(data) {
            let res = await service({
                url: `${version}/client/referral/getReferralCode`,
                method: 'get',
                params: data
            })
            if (res && res.referral_code) {
                this.setReferralCode(res.referral_code)
            }
            return res
        },
        async getMetaMaskNonce(data) {
            let res = await service({
                url: `${version}/client/metamask/nonce`,
                method: 'get',
                params: data
            })
            return res
        },
        async signature(account, message) {
            let sigature = await window.ethereum.request({
                method: "personal_sign",
                params: [
                    message, // 待签名的数据
                    account, // 用户地址
                ],
            });
            return sigature;
        },
        async metaMaskLink(data) {
            let res = await service({
                url: `${version}/client/metamask/link`,
                method: 'post',
                data
            })
            return res
        },
        async metaMaskUnLink(data) {
            let res = await service({
                url: `${version}/client/metamask/unlink`,
                method: 'post',
                data
            })
            return res
        },
        //metamask login
        async metaMaskAuth(data) {
            let res = await service({
                url: `${version}/client/metamask/auth`,
                method: 'post',
                data,
            })
            return res
        },
        async clientTgLogin(data) {
            let res = await service({
                url: `${version}/tgLogin`,
                method: 'post',
                data,
            })
            return res
        },
        async resetPassword(data) {
            let res = await service({
                url: `${version}/client/email/resetPassword`,
                method: 'post',
                data
            })
            return res;
        },
        async sendResetPasswordEmail(data) {
            let res = await service({
                url: `${version}/client/email/sendResetPasswordEmail`,
                method: 'post',
                data
            })
            return res;
        },
        async clientRegister(data) {
            let res = await service({
                url: `${version}/client/register`,
                method: 'post',
                data,
            })
            return res;
        },
        async theblockbeatsNews(data) {
            let res = await service({
                baseURL:"https://news.algo.finance",
                method: 'get',
                params:data,
                headers : {
                    'Content-Type' : 'application/json'
                }
            })
            
            return res;
        },
    }
})

export default pinia;